import { Box } from '@mui/material'
import { PlaybookPauseScreen } from '@guidde/design-system'

import { generateUrlWithDomain, openLink } from 'modules'
import { useWindowView } from 'hooks'

import { AnyPlaybookType, VideoPlayer, VideoOverlayDataType } from 'app/types'

export const checkToOpenPlaybookPauseScreen = ({
    videoPlayer,
    overlayData,
    openPauseScreen,
    isPauseScreenShowed
}: {
    isPauseScreenShowed: boolean
    videoPlayer: VideoPlayer
    overlayData: VideoOverlayDataType
    openPauseScreen: () => void
}) => {
    if (isPauseScreenShowed) {
        return
    }

    const currentTime = videoPlayer.getCurrentTime()

    if (currentTime === 0 || currentTime === videoPlayer.getDuration()) {
        return
    }

    if (
        (overlayData.isFreePlan || overlayData.isTrialPlan) &&
        !overlayData.hidePauseScreen
    ) {
        openPauseScreen()
    }
}

type Props = {
    playbook: AnyPlaybookType
    onClose: () => void
}

export const PauseScreen = ({ playbook, onClose }: Props) => {
    const { xsDown } = useWindowView()

    const onSignUpClick = () => {
        const utmParams = new URLSearchParams({
            utm_source: 'guidde-app',
            utm_medium: 'pause-screen',
            utm_campaign: 'get-guidde',
            utm_term: playbook.id,
            utm_content: playbook.uploadedByOrgId
        })

        const url = generateUrlWithDomain('/signup?' + utmParams)
        openLink(url)
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                color: '#212121',
                width: 280,
                '& > div': {
                    background: 'none'
                }
            }}
        >
            <PlaybookPauseScreen
                isMobileView={xsDown}
                authorName={playbook.uploadedBy?.displayName || ''}
                onClose={onClose}
                onSingUpClick={onSignUpClick}
            />
        </Box>
    )
}
