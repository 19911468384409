import {
    getAuth,
    OAuthProvider,
    signInWithCustomToken,
    signInWithPopup
} from 'firebase/auth'

import { SignForm as SignContent, ThemeProvider } from '@guidde/design-system'
import { Box } from '@material-ui/core'
import * as Sentry from '@sentry/react'

import { generateUrlWithDomain, logToAnalytics, request } from 'modules'
import { useAuth, useBoolean, useNotification, useWindowView } from 'hooks'

import { links } from 'app/links'

export type SignInProvider = 'google.com' | 'microsoft.com'

const extractObjectFromString = (str: string) => {
    if (typeof str !== 'string') return null

    const regex = /{.*}/
    const match = str.match(regex)

    if (!match) return null

    try {
        return JSON.parse(match[0])
    } catch (e) {
        return null
    }
}

const refreshTokenForMSLogin = async (data: {
    email: string
    idToken: string
}): Promise<{ token: string | null }> => {
    return request(`/auth/v1/ms-login`, 'POST', data)
}

export const LoginPage = () => {
    const { setLoginPage } = useAuth()

    const { xsDown } = useWindowView()
    const microsoftLoading = useBoolean()
    const loading = useBoolean()
    const { showErrorNotification } = useNotification()

    const handleLoginWithPopupError = async (
        error: any,
        signInProvider: SignInProvider
    ) => {
        if (error.code === 'auth/popup-closed-by-user') {
            return
        }

        Sentry.captureException(new Error('popup-login-failed'), {
            extra: { signInProvider, ...error }
        })

        if (error.message.includes('Please login with your SSO provider.')) {
            throw 'Please login with your SSO provider.'
        }

        const firebaseResponse = extractObjectFromString(error.message)

        if (
            firebaseResponse?.error?.details?.reason === 'environment-forbidden'
        ) {
            throw `redirectUrl:${firebaseResponse.error.details.redirect}`
        }

        if (error.message.includes('PERMISSION_DENIED')) {
            showErrorNotification(
                'This account has been deactivated. Please contact support.'
            )
            return
        }

        if (signInProvider !== 'microsoft.com') return

        if (error.code === 'auth/account-exists-with-different-credential') {
            microsoftLoading.setTrue()

            try {
                const msLoginData = await refreshTokenForMSLogin({
                    email: error.customData.email || '',
                    // It's tricky, _tokenResponse is an internal data which can be changed
                    // at any moment. So need to lock the node_module and verify that data
                    // everytime we upgrade the module
                    // @ts-ignore
                    idToken: error.customData._tokenResponse?.oauthIdToken
                })

                if (msLoginData?.token) {
                    return signInWithCustomToken(
                        getAuth(),
                        msLoginData.token
                    ).finally(microsoftLoading.setFalse)
                }
            } catch (error: any) {
                microsoftLoading.setFalse()
                if (
                    error.message.includes(
                        'Please login with your SSO provider.'
                    )
                )
                    throw 'Please login with your SSO provider.'
            }
        }

        showErrorNotification(
            'Contact your administrator to sign in with Microsoft'
        )
        microsoftLoading.setFalse()
    }

    const continueWithPopup = (signInProvider: SignInProvider) => {
        const provider = new OAuthProvider(signInProvider)

        signInWithPopup(getAuth(), provider)
            .then(() => setLoginPage(false))
            .catch(e => handleLoginWithPopupError(e, signInProvider))
    }

    const getAuthUrl = async (domain: string) => {
        const url = generateUrlWithDomain(`/auth/v1/sso/auth?domain=${domain}`)

        const response = await fetch(url)
        return response.json()
    }

    // legacy code SSO is not using in prod right now
    const onWindowMessage = async (e: any) => {
        if (e.data) {
            try {
                await signInWithCustomToken(getAuth(), e.data.token).then(
                    userCredential => {
                        logToAnalytics('sso-login', {
                            providerId: userCredential.providerId,
                            email: userCredential.user.email,
                            id: userCredential.user.uid
                        })
                    }
                )
            } catch (e) {
                Sentry.captureException(new Error('sso-login-failed'), {
                    extra: {
                        props: (e as any).data,
                        error: e
                    }
                })
            }
        }
    }

    return (
        <Box
            maxHeight="100vh"
            height="100%"
            width="100%"
            display="flex"
            overflow="auto"
            justifyContent="center"
            alignItems="center"
        >
            <ThemeProvider>
                <SignContent
                    termsLink={links.terms}
                    privacyPolicyLink={links.privacyPolicy}
                    title="Login to Guidde"
                    ssoTitle="Login to Guidde with SSO"
                    subtitle=""
                    ssoSubtitle=""
                    signAlternativeActionLabel=""
                    ssoAlternativeText=""
                    primaryButtonlabel="Log in"
                    ssoAlternativeActionLabel="Back to Log in"
                    shouldDisableMainButton={loading.isTrue}
                    shouldShowLogo={false}
                    shouldHideMagicLinkLogin={true}
                    maxWidth={xsDown ? Math.min(window.innerWidth || 250) : 500}
                    isLoading={microsoftLoading.isTrue}
                    onGoogleClick={() => continueWithPopup('google.com')}
                    onMicrosoftClick={() => continueWithPopup('microsoft.com')}
                    onSubmit={({ domain }) => {
                        loading.setTrue()

                        getAuthUrl(domain).then(res => {
                            if (res?.authUrl) {
                                // specify the properties of the popup window
                                const popupWindow = window.open(
                                    res?.authUrl,
                                    'Popup',
                                    'width=600,height=800'
                                )

                                window.addEventListener(
                                    'message',
                                    onWindowMessage
                                )

                                if (popupWindow) popupWindow.focus()
                            } else {
                                showErrorNotification(
                                    'Contact your administrator to sign in with SSO'
                                )
                            }
                        })
                        loading.setFalse()
                    }}
                />
            </ThemeProvider>
        </Box>
    )
}
